<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld /> -->
    <!-- <pdf src="https://dev-camp-1257643742.cos.ap-shanghai.myqcloud.com/protocol/%E4%B8%89%E7%AB%AF%E5%B1%8F%E5%B9%95%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F%E5%88%9D%E6%AD%A5%E6%8A%A5%E4%BB%B7.pdf"></pdf> -->
    <!-- <iframe src="https://dev-camp-1257643742.cos.ap-shanghai.myqcloud.com/protocol/%E4%B8%89%E7%AB%AF%E5%B1%8F%E5%B9%95%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F%E5%88%9D%E6%AD%A5%E6%8A%A5%E4%BB%B7.pdf"  frameborder="0" width="100%" height="100%" ></iframe> -->

    <!-- <vue-pdf-embed :source="pdfUrl" /> -->
    <!-- <PdfView :pdfUrl="pdfUrl"/> -->

    <iframe
      :src="pdfUrl"
      frameborder="0"
      height="900px"
      width="100%"
    ></iframe>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import pdf from "vue-pdf";
// import * as PdfJs from 'pdfjs-dist/legacy/build/pdf.js'

// import VuePdfEmbed from 'vue-pdf-embed'
// import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
// import PdfView from './components/PdfView.vue'
//
export default {
  name: "App",
  components: {
    // HelloWorld
    // pdf
    // PdfView
  },
  data() {
    return {
      pdfUrl: 'https://dev-camp-1257643742.cos.ap-shanghai.myqcloud.com/protocol/%E4%B8%89%E7%AB%AF%E5%B1%8F%E5%B9%95%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F%E5%88%9D%E6%AD%A5%E6%8A%A5%E4%BB%B7.pdf',
      pdfViewUrl: null,
    };
  },
  methods: {
    getPdfData() {
      this.$axios({
        method: "get",
        responseType: "blob",
        url: "https://dev-camp-1257643742.cos.ap-shanghai.myqcloud.com/protocol/%E4%B8%89%E7%AB%AF%E5%B1%8F%E5%B9%95%E7%AE%A1%E7%90%86%E7%B3%BB%E7%BB%9F%E5%88%9D%E6%AD%A5%E6%8A%A5%E4%BB%B7.pdf", //后端接口地址
      }).then(({ data }) => {
        let blob = new Blob([data], { type: "application/pdf" });
        this.pdfViewUrl = URL.createObjectURL(blob);
      });
    },
  },
};
</script>

<style>
#app {
  display: block;
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  position: absolute;
}
</style>
